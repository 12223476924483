import React from 'react';
import startCase from 'lodash.startcase';
import SgLayout from '~layouts/sg-layout';
import colours from "~1-core/_colours.scss";

const SgColours = () => {
    // From the flat exported colours object, pull out all the unique colour names.
    let finalColourArray = Object.keys(colours).map(key => {
        return `${key.split('-', 2)[0]}-${key.split('-', 2)[1]}`;
    });
    finalColourArray = [...new Set(finalColourArray)]; //removes undefinied duplicates

    // Turn the array of unique colour names back into an object
    const finalColourObj = Object.fromEntries(
        finalColourArray.map(colour => [colour, []])
    );

    //Now for every unique colour name...
    Object.keys(finalColourObj).map(key => {
        //..loop through all the colour names...
        Object.keys(colours).map(colourKey => {
            //..and if the key contains the name
            if (colourKey.includes(key)) {
                // push it into the final object
                finalColourObj[key].push(colours[colourKey]);
                return;
            }
            return;
        });
        return;
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Colours</h1>
                    <div className="sg-eg-colours">
                        {Object.keys(finalColourObj).map(key => {
                            return (
                                <div className="colour-swatch" key={key}>
                                    <h4>{startCase(key)}</h4>
                                    {Object.values(finalColourObj[key].map((colour, i) => {
                                        const value = (i+1)*100;
                                        return (
                                            <div className="colour" key={i}>
                                                <div className="eg-colour" style={{backgroundColor: colour}}></div>
                                                <span>{value}{value === 500 && ` (Primary)`}</span>
                                            </div>
                                        );
                                    }))}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgColours;
